<template>
  <cs-page>
    <a-result status="404" title="404" sub-title="抱歉！您要访问的页面不存在">
      <template #extra>
        <a-button @click="backHomeClick" type="primary">返回首页</a-button>
      </template>
    </a-result>
  </cs-page>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    backHomeClick() {
      this.$router.replace("/");
    },
  },
};
</script>